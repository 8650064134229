import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import { useCallback } from 'react';
import styles from './styles';
import theme from 'theme';
import AppLink from '../../../components/AppLink';
import useEmblaCarousel from 'embla-carousel-react';

type Props = {
  children: React.ReactNode;
  title: string;
  subtitle: string;
  showMoreLink?: string;
  px?: string;
};

const SectionsContainer = ({ children, title, showMoreLink, px }: Props) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    dragFree: true,
    direction: 'rtl',
  });
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const goNext = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollNext();
    }
  }, [emblaApi]);

  const goPrev = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollPrev();
    }
  }, [emblaApi]);

  return (
    <Stack
      sx={{
        ...styles.container,
        [theme.breakpoints.up('md')]: {
          paddingX: px ?? '7.25rem',
          marginTop: '0',
        },
      }}
      gap="0"
    >
      {!isMobile && (
        <IconButton onClick={goPrev} sx={styles.sliderControllerLeft}>
          <ArrowForwardIos />
        </IconButton>
      )}
      <Stack
        direction="column"
        width="100%"
        alignItems="center"
        justifyContent="start"
        style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}
      >
        <Stack
          width="100%"
          gap="0.2rem"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="2rem"
        >
          <Stack
            gap="0.2rem"
            flexDirection="row"
            justifyContent="start"
            alignItems="center"
          >
            <Typography sx={styles.headingTitle}>{title}</Typography>
            {/*<Typography sx={styles.headingSubTitle}>{subtitle}</Typography>*/}
          </Stack>
          {showMoreLink && (
            <AppLink href={showMoreLink}>
              <Typography sx={{ ...styles.showMore, textDecoration: 'none' }}>
                دیدن همه
              </Typography>
            </AppLink>
          )}
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          className="embla"
          overflow="hidden"
          ref={emblaRef}
        >
          <Stack className="embla__container" sx={styles.scrollContainer}>
            {children}
          </Stack>
        </Stack>
      </Stack>
      {!isMobile && (
        <IconButton onClick={goNext} sx={styles.sliderControllerRight}>
          <ArrowBackIos sx={{ marginLeft: '-1rem' }} />
        </IconButton>
      )}
    </Stack>
  );
};
export default SectionsContainer;
